@import "~antd/dist/antd.less";

.text-gradient {
    background-image: -webkit-gradient(
        linear,
        37.219838% 34.532506%,
        36.425669% 93.178216%,
        from(#ffbb29),
        to(#ff890a),
        color-stop(0.37, #ffb514)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-form {
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -160px 0 0 -160px;
    width: 320px;
    height: 320px;
    padding: 36px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

    button {
        width: 100%;
    }

    p {
        color: rgb(204, 204, 204);
        text-align: center;
        margin-top: 16px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }
}

.login-form-logo {
    text-align: center;
    cursor: pointer;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        margin-right: 8px;
    }

    span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
        font-weight: 700;
        color: @primary-color;
        .text-gradient();
    }
}

.ant-spin-container,
.ant-spin-nested-loading {
    height: 100%;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

@primary-color: #97E169;