.player-wrapper {
    position: relative;
    width: auto;
    height: 100%;
}
.spinner-fix {
    position: absolute !important;
    top: 0;
    left: 0;
}

.aspect-spacer {
    padding-bottom: 56.25%;
}
.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    transform: translate3d(0, 0, 0);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    overflow: hidden;
}
.video {
    transition: all 1s;
}
.chat-collapse-button {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    margin-right: 10px;
    margin-top: 10px;
    background: transparent !important;
    border-color: #fff !important;
    z-index: 3;
}
.question-collapse-button {
    position: absolute !important;
    right: 10px !important;
    bottom: 16px !important;
    background: transparent !important;
    border-color: #fff !important;
    color: #fff !important;
    z-index: 3;
}
.question-collapse-button span {
    color: #fff !important;
    transition: 2s all;
}
.question-container {
    transition: all 1s;
    position: absolute !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 10px !important;
    color: #fff !important;
    width: 90%;
    min-height: 80px;
    bottom: 10% !important;
    left: 5% !important;
    z-index: 2;
    .ant-card-head {
        padding-left: 10px !important;
    }
    .ant-card-head-title {
        padding: 5px !important;
    }
    .ant-card-body {
        padding: 15px !important;
    }
}
.chat-collapse-button {
    transition: all 2s;
}

.chat-collapse-button span {
    color: #fff !important;
}

.chat-collapse-button:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

.chat-container {
    display: flex;
    transition: all 2s;
    align-content: center !important;
}

.chat-box {
    transition: all 2s;
    width: 90%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4) !important;
    border: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
}

.chat-form {
    transition: all 2s;
    position: absolute;
    bottom: -10px;
    width: 96%;
    left: 2%;
}

.chat-input {
    transition: all 2s;
    height: 80px !important;
    width: 98% !important;
    margin-left: 1% !important;
    margin-bottom: 10px !important;
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 4px;
    color: #fff !important;
    resize: none;
    overflow: hidden;
}
.chat-send-button {
    float: right;
    height: 20px;
    font-weight: bold !important;
    color: #29333d !important;
    background-color: @primary-color !important;
}
.qabox {
    position: relative;
    transition: all 0.5s;
    width: 90%;
    background: transparent !important;
    margin-left: 5% !important;
    margin-top: -50px !important;
}

.qabox-input input {
    transition: all 0.5s;
    background-color: rgba(0, 0, 0, 0.5) !important;
    color: #fff !important;
    height: 35px;
    border-radius: 4px;
}

.qabox-input input:hover + span > button {
    border-color: @primary-color !important;
}

.qabox-input input:focus + span > button {
    border-color: @primary-color !important;
}

.qabox-input span {
    background: transparent !important;
    color: #fff !important;
}

.qabox-input button {
    background: transparent !important;
    border-color: #fff !important;
    height: 35px;
}
.qabox-input button:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

@primary-color: #97E169;