.countdown-container {
    overflow: hidden;
    text-align: center;
    position: absolute;
    width: 100%;
    min-width: 800px;
    top: 35%;
    margin: auto;
}
.countdown-container-center {
    margin: auto;
    max-width: 1200px;
    text-align: center;
}
.number {
    min-width: 300px !important;
}
.number span {
    min-width: 500px !important;
    font-size: 96px;
    font-weight: 900;
    color: #fff;
    text-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
    font-family: "Raleway";
}
.static {
    font-size: 26px !important;
    margin-left: 10px;
}
.coming-soon {
    font-weight: 900;
    text-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
    color: #97e169;
    font-size: 96px;
    font-family: "Raleway";
}

@primary-color: #97E169;