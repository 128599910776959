.layout {
    display: flex;
    min-height: 100vh !important;
    background-color: #29333d !important;
    transition: all 2s;
    font-family: "Raleway" !important;
}
.header {
    background-color: #29333d !important;
    height: 150px !important;
}
.footer {
    background-color: #29333d !important;
}

.logo {
    position: absolute;
    bottom: 0px !important;
    right: 1% !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    text-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
}

.footer .logobig {
    color: @primary-color!important;
    font-size: 48px;
    font-weight: bold !important;
}
.footer .logosmall {
    color: #fff !important;
    font-size: 32px !important;
    font-weight: 400 !important;
}
.container {
    padding-left: 10% !important;
    transition: all 2s;
    justify-content: center !important;
}

@primary-color: #97E169;